@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");

body {
  margin: 0;
  padding: 0;
  background: hsl(0deg 0% 10%);
  color: white;
  min-height: 100%;
  font-size: 20px;
  font-family: "EB Garamond", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
p {
  margin-top: 0;
  margin-bottom: 1em;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .app-layout {
    padding: 16px 8px !important;
  }

  .bag {
    height: auto !important;
    padding-bottom: 25% !important;
  }

  .legend {
    grid-auto-flow: row !important;
  }
}

@media only screen and (max-width: 400px) {
  .bag-footer {
    flex-direction: column !important;
  }

  .bag-container p,
  .bag-container a {
    margin-bottom: 8px;
  }
}
